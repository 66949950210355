import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Styled = styled.div``

const themen = props => {
  const { children, ...passProps } = props

  return <Styled {...passProps}>{children}</Styled>
}

themen.propTypes = {}

themen.defaultProps = {}

export default themen
